import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'
import { mobile, tablet } from '~styles/global'
import { isTablet, isMobile } from 'react-device-detect'
import FadeTransition from '~components/FadeTransition'
import Image from '~components/Image'

const MovingVideo = ({ className, src }) => {

	const [showMouse, setShowMouse] = useState(false)
	const [mouseX, setMouseX] = useState(false)
	const [mouseY, setMouseY] = useState(false)

	useLayoutEffect(() => {
		window.addEventListener('mousemove', e => {
			setShowMouse(true)
			setMouseX(e.clientX)
			setMouseY(e.clientY)
		})
		return window.removeEventListener('mousemove', e => {
			setShowMouse(true)
			setMouseX(e.clientX)
			setMouseY(e.clientY)
		})
	}, [])

	if(isMobile || isTablet) return null
	
	return (
		<>
			<Wrap 
				className={className}
				initial={{ x: - 10, y: -10 }}
				animate={{
					x: mouseX + 30,
					y: mouseY + 30,
					opacity: showMouse ? 1 : 0,
					transition: {
						type: 'spring',
						mass: 0.1,
						stiffness: 50
					}
				}}
			>
				<AnimatePresence>
					{typeof src === 'string' &&
						<Video playsInline muted loop autoPlay
							key={src}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0, transition: {delay: 0.5} }}
							transition={{ duration: 0.5}}
							className={className}
						>
							<source src={src} />
						</Video>
					}

					{typeof src === 'object' &&
						<Image image={src} key={src} />
					}
					
				</AnimatePresence>
			</Wrap>
		</>
	)
}

const Wrap = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	opacity: 0;
	width: 500px;
	${tablet}{
		width: 300px;
	}
`

const Video = styled(motion.video)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`

MovingVideo.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string,
}

export default MovingVideo